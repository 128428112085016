<template>
  <div class="mt-4">
    <div class="vx-row">
      <div class="vx-col w-full">
        <ValidationErrors :errors="errors"/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/3 w-full">
        <label class="ml-1 text-xs">No. PR *</label>
        <vs-input class="w-full" :value="data.no_pengadaan || 'Generate Otomatis'" readonly/>
      </div>
      <div class="vx-col sm:w-1/3 w-full">
        <label class="ml-1 text-xs">Tgl. Pengajuan  *</label>
        <flat-pickr class="w-full" v-model="data.tgl_pengajuan"></flat-pickr>
      </div>
      <div class="vx-col sm:w-1/3 w-full">
        <label class="ml-1 text-xs">Proyek *</label>
        <vs-select class="w-full" autocomplete v-model="data.id_proyek">
          <vs-select-item class="w-full" v-for="(it, index) in proyeks" :key="index" :value="it.id" :text="it.nama"/>
        </vs-select>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-2/ w-full">
        <label class="ml-1 text-xs">Judul PR *</label>
        <vs-input class="w-full" v-model="data.judul_pengadaan"/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/1 w-full">
        <label class="ml-1 text-xs">Keterangan</label>
        <vs-textarea class="w-full" icon v-model="data.keterangan"/>
      </div>
    </div>
    <div class="vx-row float-right mt-5">
      <div class="vx-col w-full">
        <vs-button @click="next" icon-pack="feather" icon="icon-arrow-right" icon-after>Next</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import ProyekRepository from '@/repositories/master/proyek-repository'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'TabHeader',
  props: {
    initData: { default: null, type: Object } // for edit
  },
  components: {
    ValidationErrors,
    flatPickr
  },
  mounted () {
    this.getAllProyek()
  },
  computed: {
    errors () {
      return this.$store.state.procurement.pengadaanAdd.errors.tabHeader.messages
    }
  },
  watch: {
    initData (newVal, oldVal) {
      if (!_.isEmpty(newVal) && !_.isEqual(newVal, oldVal)) {
        this.data = _.cloneDeep(newVal)
      }
    },
    data: {
      deep: true,
      handler (value) {
        this.commitDataToStore(value)
      }
    }
  },
  data () {
    return {
      data: {
        id_proyek: null,
        tgl_pengajuan: moment().format('YYYY-MM-DD')
      },
      proyeks: []
    }
  },
  methods: {
    getAllProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    commitDataToStore: _.debounce(function (value) {
      const payload = _.cloneDeep(value)
      this.$store.commit('procurement/pengadaanAdd/SET_TAB_HEADER', payload)
    }, 500),

    next () {
      this.$store.commit('procurement/pengadaanAdd/SET_ACTIVE_TAB', 1)
    }
  }
}
</script>
